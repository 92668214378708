import { useTranslation } from 'react-i18next';

import styles from './PaymentCard.module.scss';
import { getPaymentIcon } from './utils/getPaymentIcon';

export const PaymentCard = ({ methodName, onClick, className }) => {
  const { t } = useTranslation('Withdrawal');
  return (
    <div key={methodName} className={`${styles.methodCard} ${className}`} onClick={() => onClick(methodName)}>
      <span className={styles.methodsIcon}>{getPaymentIcon(methodName)}</span>
      {t(methodName)}
    </div>
  );
};
